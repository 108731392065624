import "../styles/Footer.css";

const Footer = () => {
  return (
    <>
      <p className="footer__desc">Copyright @ Kamil Skórzyński & MMStal 2021</p>
    </>
  );
};

export default Footer;
